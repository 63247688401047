<template>
    <div class="professional_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.jobSalutationAdvantageName"> </CourseTitle>
            <div class="content">
                <div class="professional_advantage">
                    <ul class="Between flex-wrap">
                        <li class="ul_li flex" v-for="(item, index) in formData.jobSalutationAdvantages" :key="index">
                            <div class="li_img">
                                <img :src="item.salutationAdvantageUrl" alt="" />
                            </div>
                            <div class="li_div">
                                <div class="li_div_title">
                                    {{ item.salutationAdvantageName }}
                                </div>
                                <div class="li_div_cont">
                                    {{ item.salutationAdvantageIntroduce }}
                                </div>
                                <div class="li_div_btn display pointer" @click="service">
                                    了解更多>>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="module2">
            <CourseTitle :title="formData.wayGetJobSalutationName"> </CourseTitle>
            <div class="content">
                <div class="getProfessional_div">
                    <ul class="getProfessional_ul flex">
                        <li class="getProfessional_li" v-for="(item, index) in formData.jobSalutationWayGets" :key="index">
                            <div class="getProfessional_li_title">
                                NO.{{ index + 1 }}{{ item.salutationWayGetName }}
                            </div>
                            <div class="getProfessional_li_line"></div>
                            <div class="getProfessional_li_cont">
                                {{ item.salutationWayGetIntroduce }}
                            </div>
                            <div class="getProfessional_li_btn pointer" @click="service">
                                了解更多>>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.projectTechnologyName" :isWhite="1">
            </CourseTitle>
            <div class="content">
                <div class="declaration_requirement">
                    <div class="declaration_requirement_list flex"
                        v-for="(item, index) in formData.jobSalutationProjectTechnologys" :key="index">
                        <div class="list_fl">
                            <img :src="item.projectTechnologyImgUrl" alt="" />
                        </div>
                        <div class="list_fr">
                            <div class="list_fr_div flex">
                                <div class="fr_div_list" v-for="(
										items, indexs
									) in item.jobSalutationProjectTechnologys" :key="indexs">
                                    <div class="title display">
                                        {{ items.projectTechnologyName }}
                                    </div>
                                    <div class="rich_text" v-html="items.projectTechnologyIntroduce"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="declaration_requirement_list flex">
						<div class="list_fl">
							<img src="@/assets/img/professional/3.png" alt="" />
						</div>
						<div class="list_fr">
							<div class="list_fr_div flex">
								<div class="fr_div_list">
									<div class="title display">技术员</div>
									<div class="rich_text">
										（1）具备大学专科、中等职业学校毕业学历，在工程技术
										岗位上见习1年期满，经考察合格；
										（2）具备大学本科学历或学士学位；
										（3）技工院校毕业生按国家有关规定申报。
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="declaration_requirement_list flex">
						<div class="list_fl">
							<img src="@/assets/img/professional/4.png" alt="" />
						</div>
						<div class="list_fr">
							<div class="list_fr_div flex">
								<div class="fr_div_list">
									<div class="title display">技术员</div>
									<div class="rich_text">
										（1）具备大学专科、中等职业学校毕业学历，在工程技术
										岗位上见习1年期满，经考察合格；
										（2）具备大学本科学历或学士学位；
										（3）技工院校毕业生按国家有关规定申报。
									</div>
								</div>
							</div>
						</div>
					</div> -->
                </div>
            </div>
        </div>
        <div class="module4">
            <CourseTitle :title="formData.projectHotProfessionalName"> </CourseTitle>
            <div class="content">
                <div class="popula_major">
                    <ul class="popula_major_ul flex">
                        <li class="popula_major_li display" v-for="(item, index) in formData.jobSalutationProjectHots"
                            :key="index">
                            {{ item.projectHotName }}
                            <ul class="li_ul">
                                <li class="li_ul_li display pointer"
                                    v-for="(items, indexs) in item.jobSalutationProjectHots" :key="indexs">
                                    {{ items.projectHotName }}
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div class="btn display" @click="service">点击咨询更多专业</div>
                </div>
            </div>
        </div>
        <div class="module5">
            <CourseTitle :title="formData.takeTestName"> </CourseTitle>
            <div class="content">
                <TakeTest :takeTestData="formData.specialQuestionBankVOS"></TakeTest>
            </div>
        </div>
        <div class="module6">
            <CourseTitle :title="formData.agencyAgentName"> </CourseTitle>
            <div class="content">
                <div class="why_Agent display">
                    <div class="self_declaration">
                        <div class="title display">
                            <span>自己申报</span> （准备时间长达半年）
                        </div>
                        <ul>
                            <li class="self_declaration_li flex" v-for="(item, index) in jobSalutationGolgaServicesFl"
                                :key="index">
                                <div class="num">0{{ index + 1 }}</div>
                                <div class="cont">
                                    {{ item.agencyAgentName }} {{ item.agencyAgentIntroduce }}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <img class="pk_img" src="@/assets/img/professional/PK.png" alt="" />
                    <div class="self_declaration agent_self_declaration">
                        <div class="title agent_title display">
                            <span>专业代办</span>（省时省心省力）
                        </div>
                        <ul>
                            <li class="self_declaration_li flex" v-for="(item, index) in jobSalutationGolgaServicesFr"
                                :key="index">
                                <div class="num">0{{ index + 1 }}</div>
                                <div class="cont flex-center">
                                    <div class="cont_label nowrap">
                                        {{ item.agencyAgentName }}
                                    </div>
                                    {{ item.agencyAgentIntroduce }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.golgaServiceName"> </CourseTitle>
            <div class="content">
                <div class="gej_service_div">
                    <div class="gej_service_navBar Between">
                        <div class="list display pointer" :class="navIndex == index ? 'navActive' : ''"
                            v-for="(item, index) in formData.jobSalutationGolgaServices" :key="index"
                            @click="changeBtn(item, index)">
                            {{ item.golgaServiceName }}
                        </div>
                    </div>
                    <div class="cont_div flex">
                        <div class="cont_div_fl">
                            <div class="fl_title">{{ gejService.golgaServiceName }}</div>
                            <div class="fl_content">
                                {{ gejService.golgaServiceIntroduce }}
                            </div>
                            <div class="fl_btn display pointer" @click="service">
                                了解更多>>
                            </div>
                        </div>
                        <div class="cont_div_fr">
                            <img :src="gejService.golgaServiceUrl" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module8">
            <CourseTitle :title="formData.materialDownloadName" :isWhite="1">
            </CourseTitle>
            <div class="content">
                <ObtainData :freeData="formData.jobSalutationMaterials"></ObtainData>
            </div>
        </div>
        <div class="module9">
            <CourseTitle :title="formData.problemSolvingName"> </CourseTitle>
            <div class="content">
                <ProblemSolving :ProblemSolvingData="formData.jobSalutationQuestions"></ProblemSolving>
            </div>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
import ObtainData from '@/components/obtainData.vue'
import ProblemSolving from '@/components/problemSolving.vue'
import TakeTest from '@/components/takeTest'
export default {
    components: {
        BannerImg,
        CourseTitle,
        ObtainData,
        ProblemSolving,
        TakeTest,
    },
    data() {
        return {
            navIndex: 0,
            formData: {},
            gejService: {},
            jobSalutationGolgaServicesFl: [],
            jobSalutationGolgaServicesFr: [],
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.professionalApi().then((res) => {
                this.formData = res.data
                this.gejService = this.formData.jobSalutationGolgaServices[0]
                this.formData.jobSalutationGolgaServices

                let arr = []
                let arrs = []
                this.formData.jobSalutationAgencyAgents.filter((item, index) => {
                    if (index % 2 == 0) {
                        arr.push(item)
                    } else {
                        arrs.push(item)
                    }
                })
                this.jobSalutationGolgaServicesFl = arr
                this.jobSalutationGolgaServicesFr = arrs
                console.log(this.jobSalutationGolgaServicesFl)
                console.log(this.jobSalutationGolgaServicesFr)
            })
        },
        //点击果尔佳省心服务分类
        changeBtn(item, index) {
            this.navIndex = index
            this.gejService = item
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.professional_page {
    .module1 {
        width: 100%;
        height: 730px;
        background: #fafafa;

        .professional_advantage {
            margin-top: 31px;

            ul {
                .ul_li {
                    width: 590px;
                    height: 230px;
                    background: #fff;
                    margin-bottom: 20px;

                    .li_img {
                        width: 320px;

                        img {
                            width: 320px;
                            height: 230px;
                        }
                    }

                    .li_div {
                        width: 270px;
                        padding: 40px 37px 0 39px;
                        box-sizing: border-box;

                        .li_div_title {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #003c7e;
                            line-height: 24px;
                        }

                        .li_div_cont {
                            width: 192px;
                            height: 40px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            margin-top: 19px;
                        }

                        .li_div_btn {
                            width: 110px;
                            height: 36px;
                            background: #ff881e;
                            border-radius: 5px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                            margin-top: 48px;
                        }
                    }
                }
            }
        }
    }

    .module2 {
        height: 530px;

        .getProfessional_div {
            margin-top: 9px;

            .getProfessional_ul {
                .getProfessional_li:hover {
                    background: #003c7e;
                    border: 1px solid #dddddd !important;

                    .getProfessional_li_title {
                        color: white !important;
                    }

                    .getProfessional_li_line {
                        background: white !important;
                    }

                    .getProfessional_li_cont {
                        color: white !important;
                    }

                    .getProfessional_li_btn {
                        display: block;
                    }
                }

                .getProfessional_li {
                    width: 260px;
                    height: 300px;
                    border: 1px solid #fff;
                    padding: 50px 20px 0 25px;
                    box-sizing: border-box;
                    margin-left: 53px;

                    .getProfessional_li_title {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                    }

                    .getProfessional_li_line {
                        width: 30px;
                        height: 4px;
                        background: #333333;
                        margin-top: 25px;
                    }

                    .getProfessional_li_cont {
                        width: 208px;
                        height: 87px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 24px;
                        margin-top: 27px;
                    }

                    .getProfessional_li_btn {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ff881e;
                        line-height: 24px;
                        margin-top: 15px;
                        display: none;
                    }
                }
            }
        }
    }

    .module3 {
        width: 100%;
        height: 1333px;
        background: url('../../assets/img/professional/bg1.png') no-repeat;
        background-size: 100% 100%;

        .declaration_requirement {
            margin-top: 31px;

            .declaration_requirement_list:nth-child(1) {
                .list_fr {
                    .fr_div_list:nth-child(1) {
                        .rich_text {
                            width: 400px !important;
                        }
                    }
                }
            }

            .declaration_requirement_list:nth-child(2) {
                .title {
                    background: #2a2237 !important;
                }

                .rich_text {
                    width: 600px !important;
                }
            }

            .declaration_requirement_list:nth-child(3) {
                height: 370px !important;

                .title {
                    background: #b30d0c !important;
                }

                .list_fr {
                    .fr_div_list:nth-child(1) {
                        .rich_text {
                            width: 415px !important;
                        }
                    }
                }
            }

            .declaration_requirement_list {
                width: 100%;
                height: 300px;
                background: white;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                margin-bottom: 30px;

                .list_fl {
                    width: 220px;
                    margin-right: 34px;

                    img {
                        width: 220px;
                        height: 100%;
                    }
                }

                .list_fr {
                    padding-top: 32px;

                    .list_fr_div {
                        .fr_div_list:nth-child(2) {
                            .title {
                                width: 148px;
                            }
                        }

                        .fr_div_list {
                            width: 444px;

                            .title {
                                width: 120px;
                                height: 44px;
                                background: #214a3d;
                                border-radius: 5px;
                                box-sizing: border-box;
                                font-size: 18px;
                                font-family: Microsoft YaHei;
                                font-weight: bold;
                                color: #ffffff;
                                margin-bottom: 16px;
                            }

                            .rich_text {
                                width: 478px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }
        }
    }

    .module4 {
        height: 862px;
        position: relative;

        .popula_major {
            margin-top: 39px;

            .popula_major_ul {
                .popula_major_li {
                    width: 200px;
                    height: 68px;
                    background: #003c7e;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    border-right: 1px solid #fff;
                    position: relative;
                    border-bottom: 1px solid #dedede;

                    .li_ul {
                        position: absolute;
                        top: 69px;
                        left: 0;
                        background: #f4f5f5;

                        .li_ul_li {
                            width: 199px;
                            height: 68px;
                            color: #333333;
                            font-size: 18px;
                            border-right: 1px solid #dedede;
                            border-bottom: 1px solid #dedede;
                        }

                        .li_ul_li:hover {
                            background: #003c7e !important;
                            color: #ffffff !important;
                        }

                        .listActive {
                            background: #003c7e !important;
                            color: #ffffff !important;
                        }
                    }
                }

                .popula_major_li:last-child {
                    border-right: 0 !important;

                    .li_ul {
                        .li_ul_li {
                            border-right: 0 !important;
                        }
                    }
                }
            }
        }

        .btn {
            width: 428px;
            height: 48px;
            background: #ff881e;
            border-radius: 5px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            position: absolute;
            bottom: 110px;
            left: 50%;
            margin-left: -214px;
        }
    }

    .module5 {
        height: 720px;
        background: url('../../assets/img/professional/bg2.png') no-repeat !important;
        background-size: 100% 100%;

        .test_div {}
    }

    .module6 {
        height: 720px;
        background: #fafafa;

        .why_Agent {
            margin-top: 30px;

            .self_declaration {
                .title {
                    font-size: 24px;
                    color: #333333;
                    margin-left: 69px;
                    margin-bottom: 29px;

                    span {
                        color: #333;
                        font-size: 24px;
                        font-weight: bold;
                        font-family: Microsoft YaHei;
                    }
                }

                ul {
                    .self_declaration_li {
                        width: 418px;
                        height: 70px;
                        background: url('../../assets/img/professional/bg3.png') no-repeat;
                        background-size: 100% 100%;
                        margin-bottom: 15px;

                        .num {
                            font-size: 36px;
                            font-family: Arial;
                            font-weight: bold;
                            color: #ffffff;
                            line-height: 24px;
                            margin-left: 12px;
                            margin-top: 22px;
                        }

                        .cont {
                            width: 240px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #fafafa;
                            line-height: 24px;
                            margin-top: 16px;
                            margin-left: 41px;
                        }
                    }
                }
            }

            .pk_img {
                width: 168px;
                height: 168px;
                margin: 0px 48px;
                margin-top: 40px;
            }

            .agent_self_declaration {
                .agent_title {
                    color: #003c7e;

                    span {
                        color: #003c7e;
                    }
                }

                .self_declaration_li {
                    background: url('../../assets/img/professional/bg4.png') no-repeat !important;
                    background-size: 100% 100%;

                    .cont {
                        width: 100% !important;
                        margin-top: 0 !important;

                        .cont_label {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }

    .module7 {
        height: 720px;

        .gej_service_div {
            .gej_service_navBar {
                width: 1200px;
                height: 54px;
                background: #f5f5f5;
                border-radius: 27px;

                .list {
                    width: 240px;
                    height: 54px;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }

                .navActive {
                    background: #023a7c;
                    border-radius: 27px;
                    color: #ffffff !important;
                }
            }

            .cont_div {
                background: #fafafa;
                margin-top: 37px;

                box-sizing: border-box;

                .cont_div_fl {
                    width: 359px;
                    height: 360px;
                    padding-top: 59px;

                    .fl_title {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #023a7c;
                        line-height: 24px;
                        border-left: 4px solid #023a7c;
                        margin-left: 37px;
                        padding-left: 10px;
                    }

                    .fl_content {
                        width: 220px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 29px;
                        margin-left: 35px;
                    }

                    .fl_btn {
                        width: 166px;
                        height: 48px;
                        background: #fafafa;
                        border: 1px solid #ff881e;
                        border-radius: 24px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ff881e;
                        margin-top: 50px;
                        margin-left: 29px;
                    }
                }

                .cont_div_fr {
                    width: 840px;
                    height: 360px;

                    img {
                        width: 840px;
                        height: 360px;
                    }
                }
            }
        }
    }

    .module8 {
        min-height: 740px;
        background: url('../../assets/img/professional/bg5.png') no-repeat !important;
        background-size: 100% 100%;
    }

    .module9 {
        padding-bottom: 62px;
        background: #fafafa;
    }
}</style>